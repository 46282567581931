import React from 'react'
import {graphql} from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../layout'
import SEO from '../components/seo'
import {DangerousHTML} from '../components/contentful/html'
import Image from '../components/contentful/image'

const FacilitiesPage = ({data}) => (
	<Layout>
		<Helmet>
			<body className="facilities-page" />
		</Helmet>
		<div className="inset facilities">
			<div className="rentals">
				<SEO {...data.page?.metadata} />
				<DangerousHTML>{data.page?.body.html.content}</DangerousHTML>
			</div>
			<div className="facilities-images">
				<ul>
					{data.images.items.map(image => (
						<li>
							<Image {...image.file} />
						</li>
					))}
				</ul>
			</div>
		</div>
	</Layout>
)

export default FacilitiesPage

export const query = graphql`
	{
		page: contentfulBasicPage(title: {eq: "Facilities"}) {
			body {
				html: childMarkdownRemark {
					content: html
				}
			}
			metadata {
				...ContentfulMetadataFragment
			}
		}
		images: contentfulList(name: {eq: "Facilities Page Images"}) {
			items {
				... on ContentfulImage {
					file: image {
						...ContentfulImageFragment
					}
				}
			}
		}
	}
`
